import {
  ref, onMounted, getCurrentInstance, watch
} from 'vue'
import { useStore } from 'vuex'
import { paymentUseCase } from '@/domain/usecase'
import PaymentForm from '@/views/master/transactions/payment/PaymentForm.vue'
import { useRoute } from 'vue-router'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

export default {
  components: {
    PaymentForm
  },
  setup() {
    const app = getCurrentInstance()
    const { $toast, $strInd } = app!.appContext.config.globalProperties
    const store = useStore()
    const dataEdit = ref(null)
    const sliderActive = ref(false)
    const data = ref([])
    const inputSearch = ref('')
    const filters = {
      search: '',
      page: 0,
      top: 0
    }
    const route = useRoute()

    const redirectAdd = () => {
      dataEdit.value = null
      sliderActive.value = true
    }

    const redirectEdit = (val: any) => {
      dataEdit.value = val
      sliderActive.value = true
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    const getAllData = async (search: any = '') => {
      store.dispatch('showLoading')
      filters.search = search
      const response = await paymentUseCase.getAll(filters)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        data.value = response.result
      }

      store.dispatch('hideLoading')
    }

    onMounted(() => {
      getAllData()
    })

    return {
      data,
      inputSearch,
      dataEdit,
      sliderActive,
      route,
      getAllData,
      closeSidebar,
      redirectAdd,
      redirectEdit,
      checkPermission,
      PermissionEnum
    }
  }
}
