import {
  ref, reactive, getCurrentInstance, onMounted, watch
} from 'vue'
import { paymentUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { useRoute } from 'vue-router'

export default {
  props: ['dataEdit'],
  emits: ['reloadData', 'closeSidebar'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const route = useRoute()
    const store = useStore()
    const id = ref('')
    const title = ref('')
    const dataForm = reactive({
      code: '',
      name: '',
      // attr1: '',
    })
    const submitted = ref(false)
    const { dataEdit } = reactive(props)

    const rules = {
      name: {
        required: helpers.withMessage('Nama pembayaran harus diisi', required)
      },
      code: {
        required: helpers.withMessage('Kode pembayaran arus diisi', required)
      }
    }

    const v$ = useVuelidate(rules, dataForm)

    const definitionDataForm = (val: any) => {
      if (val) {
        title.value = 'Edit Pembayaran'
        id.value = val.Id
        dataForm.code = val.Kode
        dataForm.name = val.Nama
        // dataForm.attr1 = val.Attribute1
      } else {
        title.value = 'Tambah Pembayaran'
        id.value = ''
        dataForm.code = ''
        dataForm.name = ''
        // dataForm.attr1 = ''
      }
    }

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const checkReponseSubmit = (response: any) => {
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        emit('reloadData')
        closeSidebar()
      }
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      $confirm.require({
        header: 'Pembayaran',
        message: $strInd.confirm.msgSaveNoDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const data = {
            Kode: dataForm.code.toUpperCase(),
            Nama: dataForm.name,
            // Attribute1: dataForm.attr1,
          }
          store.dispatch('showLoading')
          const response = await paymentUseCase.submitData(id.value, data)
          checkReponseSubmit(response)
        }
      })
    }

    onMounted(() => {
      definitionDataForm(dataEdit)
    })

    return {
      title,
      dataForm,
      v$,
      submitted,
      submitData,
      closeSidebar,
      route
    }
  }
}
